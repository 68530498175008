.project-status-page {
  @apply container mx-auto space-y-8 md:space-y-12;
}

.project-status-page .project-table thead tr th {
  @apply grid items-center
}

.project-status-page .project-table thead tr th h2 {
  @apply text-lg md:text-xl;
}

.project-status-page .page-header {
  @apply flex justify-between items-end;
}

.project-status-page .page-header .col-2 .semrush-status {
  @apply flex flex-col md:flex-row gap-2 md:gap-4;
}

.project-status-page .page-header .col-2 .semrush-status .status-item {
  @apply flex flex-col gap-1
}

.project-status-page .page-header .col-2 .semrush-status .status-item h3 {
  @apply text-xs font-bold text-center;
}

.project-status-page .page-header .col-2 .semrush-status .status-item p {
  @apply uppercase text-center font-mono
}

.project-status-page .page-header .col-2 .semrush-status .status-item .available {
  @apply text-[#3AE500]
}

.project-status-page .page-header .col-2 .semrush-status .status-item .unavailable {
  @apply text-watermelon-500
}