details {
  @apply relative flex;
}

details[open] summary::-webkit-details-marker,
details summary::-webkit-details-marker {
  display: none;
}

details[open] {
  color: white;

  summary #cover {
    display: block;
  }
}

details:focus {
  outline: none;
}

details > summary {
  @apply 
    relative 
    w-8
    aspect-square
    flex items-center justify-center 
    list-none
    whitespace-nowrap
    cursor-pointer
    transition-all duration-200 ease-in-out
    outline-transparent
    rounded
    border 
    border-[#0561B7] border-opacity-25
    dark:border-[#0561B7] dark:border-opacity-50
    text-deep-space text-opacity-70
    dark:text-white dark:text-opacity-100
    bg-[#FCFCFC] dark:bg-[#0561B7] dark:bg-opacity-10
    hover:bg-[#0561B7] hover:bg-opacity-10
    hover:dark:bg-opacity-50
    hover:ring-1 hover:ring-[#0561B7] hover:ring-opacity-50
    hover:dark:ring-opacity-100
    hover:text-deep-space dark:hover:text-white
    hover:no-underline
    focus-visible:bg-[#0561B7] 
    focus-visible:bg-opacity-10
    focus-visible:dark:bg-opacity-50
    focus-visible:ring 
    focus-visible:ring-[#0561B7] focus-visible:ring-opacity-50
    focus-visible:dark:ring-opacity-100
    appearance-none;
}

details > summary #cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  cursor: default;
}

.dropdown-menu {
  @apply 
    absolute top-0 -right-[calc(100%-32px)]
    w-[calc(100vw-3rem)] md:w-[calc(100vw-4rem)] max-w-[500px]
    p-4 
    z-10 
    rounded
    text-white 
    border border-white 
  dark:border-white dark:border-opacity-30
  bg-white bg-opacity-30
  dark:bg-black dark:bg-opacity-30
    backdrop-blur-xl backdrop-saturate-150
    shadow-lg dark:shadow-none;
}

.reset-btn {
  @apply px-6 py-3 text-base text-deep-space dark:text-white hover:dark:bg-white hover:bg-opacity-10 hover:bg-deep-space hover:dark:bg-opacity-10 rounded
}

/* SLIDER
======================================= */
.chart-settings input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  appearance: none;
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  background-color: var(--input-bg);
  
  @apply border border-[#e5e0df] dark:border-[#333];
}

.chart-settings input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
}

.chart-settings input[type=range]:focus {
  @apply 
    outline-transparent;
}

.chart-settings input[type=range]::-ms-track {
  @apply 
    w-full 
    bg-transparent
    border border-transparent
    text-transparent
    cursor-pointer;
}

/* Special styling for WebKit/Blink */
.chart-settings input[type=range]::-webkit-slider-thumb {
  border: 1px solid #333;
  @apply 
    h-[37px] w-4
    mt-[-15px]
    bg-[#F5F5F5] hover:bg-[#F9F9F9]
    dark:bg-white dark:hover:bg-[#C0D7ED]
    rounded-[3px]
    cursor-pointer
    appearance-none
    transition-colors duration-150 ease-in-out;

}

/* All the same stuff for Firefox */
.chart-settings input[type=range]::-moz-range-thumb {
  border: 1px solid #333;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: var(--range-bg);
  cursor: pointer;
}

/* All the same stuff for IE */
.chart-settings input[type=range]::-ms-thumb {
  border: 1px solid #333;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: var(--range-bg);
  cursor: pointer;
}

.chart-settings input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  background: var(--gradient);
  border-radius: 1.3px;
  border: 0.2px solid var(--primary-text-color);
}

.chart-settings input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--gradient);
  @apply ring ring-[#0561B7];
}

.chart-settings input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: var(--gradient);
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

.chart-settings input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
.chart-settings input[type=range]::-ms-fill-lower {
  background: var(--gradient);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
}
.chart-settings input[type=range]:focus::-ms-fill-lower {
  background: var(--gradient);
  @apply ring ring-[#0561B7];
}
.chart-settings input[type=range]::-ms-fill-upper {
  background: var(--gradient);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  @apply ring ring-[#0561B7];
}
.chart-settings input[type=range]:focus::-ms-fill-upper {
  background: var(--gradient);
  @apply ring ring-[#0561B7];
}