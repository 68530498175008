.project-filter {
  @apply max-w-[230px] float-right hidden md:block;
}

.filter-btn-container {
  @apply
    text-sm
    p-4
    flex gap-4
    md:text-base
    justify-between
    items-center
    rounded
    border border-[#0561B7] border-opacity-[0.25] 
    dark:border-[#0561B7] dark:border-opacity-50
    bg-[#FCFCFC] dark:bg-transparent
}

.filter-btn {
  @apply 
    grid place-items-center 
    bg-white 
    rounded-full 
    border border-white
    focus-visible:ring ring-[#0561B7]
}

.filter-btn.lowest {
  @apply text-[#2DB300];
}

.filter-btn.low {
  @apply text-[#3AE500];
}

.filter-btn.moderate {
  @apply text-[#FF7347];
}

.filter-btn.high {
  @apply text-watermelon-500;
}

.filter-btn.highest {
  @apply text-[#B2294B];
}