.cluster-overview-container {
  @apply 
    relative 
    mt-6
    flex-1 
    grid 
    place-content-center
    grid-cols-1 md:grid-cols-2 md:grid-rows-2
    col-span-2 
    gap-2 gap-y-10 lg:gap-10 xl:gap-y-16;
}

.cluster-overview-box {
  @apply 
    relative 
    p-4 pt-12
    flex-1 
    rounded-md
    border border-[#0561B7] border-opacity-50
    bg-[#0561B7] bg-opacity-10;
}

.cluster-overview-heading {
  @apply 
    absolute
    -top-5 left-[18%] right-[18%]
    text-center
    p-1 lg:px-1
    rounded
    font-semibold
    text-lg
    border border-dotted border-[#0561B7] dark:border-watermelon-500
    bg-[#E9EFF7] dark:bg-[#060A2E];
}

.cluster-overview-subbox {
  @apply 
    relative 
    p-4 lg:py-6
    flex-1 
    content-center 
    text-center 
    xl:text-2xl
    border border-dotted border-[#0561B7]
    rounded-md 
    bg-white dark:bg-deep-space;
}

.cluster-overview-subbox-smallerPadding {
  @apply 
    relative 
    p-2 pt-3 lg:p-4
    flex-1 
    content-center 
    text-center 
    rounded-md 
    xl:text-2xl
    border border-dotted border-[#0561B7]
    bg-white dark:bg-deep-space;
}

.cluster-overview-clickable {
  @apply 
    relative 
    bg-white dark:bg-deep-space 
    hover:bg-[#0561B7] dark:hover:bg-[#0561B7] 
    hover:bg-opacity-[0.01] hover:dark:bg-opacity-20 
    cursor-pointer;
}

.cluster-overview-label {
  @apply 
    absolute 
    -top-3 left-1/2 -translate-x-1/2
    px-2
    text-sm 
    text-center 
    rounded 
    bg-[#E9EFF7] dark:bg-[#060A2E]
    border border-dotted border-[#0561B7];
}

.cluster-overview-overlay {
  @apply 
    absolute 
    inset-0 -top-5
    p-4 lg:px-8 
    flex flex-col items-center gap-8
    rounded 
    border border-[#0561B7] border-opacity-50
    bg-white dark:bg-black
    bg-opacity-50 dark:bg-opacity-50
    backdrop-blur-lg;
}

.cluster-overview-overlay-close {
  @apply 
    absolute 
    top-1 right-1 
    aspect-square 
    p-2 py-1 
    leading-none 
    rounded-full 
    hover:bg-black dark:hover:bg-white 
    hover:bg-opacity-10 hover:dark:bg-opacity-10 
    cursor-pointer;
}

.cluster-overview-overlay-heading {
  @apply text-2xl font-bold;
}

.cluster-overview-overlay-text {
  @apply text-lg;
}
