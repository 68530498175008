.cluster-table {
  @apply relative w-full min-w-[1024px] 
}

.cluster-table thead th {
  @apply p-4 max-w-[200px]
}

.cluster-table tbody td {
  @apply p-4 max-w-[200px]
}

.cluster-table thead .spark,
.cluster-table tbody .spark {
  @apply w-[286px] max-w-[286px]
}

.cluster-table tbody .spark {
  @apply p-0
}

.cluster-table .highcharts-dark.highcharts-dashboards--fullscreen,
.cluster-table .highcharts-light.highcharts-dashboards--fullscreen,
.cluster-table .highcharts-dark.highcharts-dashboards--fullscreen .highcharts-dashboards-wrapper,
.cluster-table .highcharts-light.highcharts-dashboards--fullscreen .highcharts-dashboards-wrapper {
  @apply h-[75px] max-h-[75px] overflow-hidden
}