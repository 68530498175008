.project-table {
  @apply 
    w-full 
    border-collapse;
}

.project-table tr {
  @apply grid;

  grid-template-columns: 1fr minmax(80px, 0.25fr);
}

/* tablet up */
@media screen and (min-width: 768px) {
  .project-table tr {
    grid-template-columns: minmax(300px, 1fr) minmax(150px, 0.25fr) minmax(150px, 0.25fr);
  }
}

.project-table thead tr {
  @apply 
    bg-[#F5F5F5] bg-opacity-100 dark:bg-[#0561B7] dark:bg-opacity-10
    font-sans
    text-left;
}

.project-table th {
  @apply text-xs md:text-sm p-4 align-middle
}

.project-table th h2 {
  @apply text-lg md:text-2xl text-deep-space dark:text-[#C0D7ED]
}

.project-table th a h2 {
  @apply hover:text-[#0561B7] dark:hover:text-watermelon-500
}

.project-table tbody tr {
  @apply relative;
}

.project-table tbody {
  @apply 
   text-sm md:text-base
}

.project-header {
  @apply flex items-center gap-4 min-h-[48px];
}

.project-table tbody tr td {
  @apply py-4 px-2 md:p-4 self-center;
}

.project-table tbody tr .spacer {
  @apply absolute bottom-0 left-4 right-4 h-[1px] p-0 bg-pink-700 bg-opacity-10 dark:bg-white dark:bg-opacity-10
}

.project-table tbody tr:last-child .spacer {
  @apply bg-opacity-0;
}

/* PROJECT STATUS
====================================== */
.project-table.status {
  @apply min-w-[1024px]
}

.project-table.status tr {
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) 150px 175px 175px;  
}

.project-table.complete tr {
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) 150px 150px 175px 110px 110px;
}

.project-table.status tbody tr {
  @apply min-h-[80px];
}

.project-table.status tr td.no-data {
  @apply text-center w-full;
  grid-column: 1 / -1;
}


.project-table.status td:not(.no-data) {
  @apply flex items-center gap-4 w-full flex-1;
}
