.cluster-overview {
  @apply 
    p-2 lg:p-4
    flex flex-col gap-4 md:gap-x-8
    md:grid;

    grid-template-columns: 384px auto;
}

.cluster .page-section > .section-header,
.cluster .page-section > div > .section-header {
  @apply rounded border-b border-dotted border-deep-space dark:border-[#C0D7ED] border-opacity-50;
}

.cluster > .page-section:first-of-type {
  @apply border-[#0561B7] border-opacity-50 dark:border-watermelon-500 dark:border-opacity-100;
}

.cluster > .page-section:first-of-type > .section-header {
  @apply border-none;
}

.cluster .section-header h1,
.cluster .section-header h2 {
  @apply text-deep-space dark:text-[#C0D7ED];
}

.cluster .page-section > .section-header p {
  @apply text-xl font-normal dark:text-white;
}

.cluster-overview .cluster-img {
  @apply 
    md:w-[384px] md:h-[384px]
    rounded 
    object-cover 
    place-self-center
    cursor-pointer 
    content-center
    col-span-full lg:col-span-1
    border border-[#0561B7];
}

.cluster-overview .info-boxes {
  @apply grid gap-2 col-start-1
    lg:grid-cols-2
    lg:row-start-2 
    lg:col-span-full
    lg:col-start-1
    xl:col-start-2;
}

.cluster .plan-btn {
  @apply text-sm flex gap-2 items-center rounded border border-[#0561B7] border-opacity-50 hover:border-opacity-100 p-2 px-3 h-full whitespace-nowrap;

  width: 2.25rem;
  overflow: clip;
  interpolate-size: allow-keywords;
  transition: width;

  &:hover {
    width: auto;
  }
  &:hover span {
    @apply opacity-100
  }
}

.vertical-divider {
  @apply border-r border-[#0561B7] border-opacity-30;
}

.soundtrack a {
  @apply no-underline;


  &:hover h2 {
    @apply text-watermelon-500
  }
}