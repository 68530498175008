.cluster-feed .cluster-list {
  @apply 
    p-2 md:p-4 md:py-7 md:pt-4 grid sm:grid-cols-2 md:grid-cols-3
    gap-2 md:gap-4;
}

.cluster-feed .cluster-list .cluster-item {
  @apply grid grid-rows-subgrid rounded overflow-hidden hover:border-[var(--hover-color)] focus-within:ring ring-[#0561B7] focus-within:dark:ring-[var(--hover-color)] focus-within:ring-opacity-50;
}

.cluster-feed .cluster-list .cluster-link {
  @apply grid hover:text-[var(--hover-color)] hover:no-underline focus:outline-none focus:ring-0 items-baseline;

  grid-template-rows: auto auto auto
}

.cluster-feed .cluster-list .cluster-link h2 span {
  display: -webkit-box;
  -webkit-line-clamp: 2;      /* Limit the text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; 
 
  @apply text-xl
}

.cluster-feed .cluster-list .cluster-item:hover h2 {
  /* add opacity to the hover color for bg */
  --hover-with-opacity: rgba(var(--hover-color-rgb), 0.10);

  @apply bg-[var(--hover-with-opacity)] text-deep-space dark:text-white;
}

.cluster-feed .cluster-list .cluster-item .indicator {
  will-change: bottom, left;
  transition: bottom 100ms, left 100ms, box-shadow 200ms;
}

.cluster-feed .cluster-list .cluster-item:hover .indicator {
  bottom: -1rem;
  left: -1rem;
  background-color: rgba(var(--hover-color-rgb), 0.7) !important;
  border-width: 2px !important;
  box-shadow: 0 0 0 4px rgba(var(--hover-color-rgb), 0.3);
  will-change: bottom, left;
  transition: bottom 100ms, left 100ms;
}

.cluster-feed .cluster-list .cluster-link p {
  @apply min-h-[112px]
}

.cluster-feed .cluster-list .cluster-link p span {
  display: -webkit-box;
  -webkit-line-clamp: 4;      /* Limit the text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; 

  @apply text-deep-space dark:text-white;
}