.keywords-form-group {
  @apply w-full rounded;
}

.keywords-form-group:has(input:focus) {
  @apply outline-none ring-2 ring-[#0561B7] ring-opacity-100
}

.keywords-form-group label {
  @apply 
    w-full block
    py-2 px-3
    bg-black dark:bg-[#0561B7] 
    bg-opacity-[2.5%] dark:bg-opacity-5
    rounded rounded-b-none;
}

.keywords-form-group .keyword-input {
  @apply flex gap-1;
}

.keywords-form-group .keyword-input input {
  @apply
  px-4 py-2
  flex-1 
  border-none
  disabled:opacity-40 disabled:cursor-not-allowed
}

.keywords-form-group .keyword-input button {
  @apply 
    min-w-[60px] 
    px-4 
    border border-[#0561B7] border-opacity-[0.25]
    dark:border-[#0561B7] dark:border-opacity-50
    rounded
    bg-[#FCFCFC]
    dark:bg-[#0561B7] dark:bg-opacity-10
    cursor-pointer
    text-black text-opacity-70
    dark:text-white dark:text-opacity-100
    hover:bg-[#0561B7] hover:bg-opacity-10
    hover:dark:bg-opacity-20
    hover:border-2 hover:border-opacity-100
    focus:outline-none
    focus:border-2 focus:border-opacity-100
    focus:bg-opacity-50 dark:focus:bg-opacity-50
    disabled:opacity-40 disabled:cursor-not-allowed
}

.keywords-group {
  @apply 
    relative
    min-h-[100px]
    p-2 pb-7 
    md:p-4 md:pb-7
    flex flex-wrap gap-2 md:gap-4 
    bg-black bg-opacity-[2.5%]
    dark:bg-[#0561B7] dark:bg-opacity-5
}

.keywords-group .keywords-count {
  @apply 
    min-w-[70px]
    absolute bottom-0 right-0
    p-1 px-1
    bg-[#FCFCFC]
    dark:bg-[#0561B7] dark:bg-opacity-10
    border-[#0561B7] border-opacity-[0.25]
    dark:border-[#0561B7] dark:border-opacity-50
    rounded-tl
    border-l border-t
    text-center
    text-xs;
}

.keywords-group .keywords-count.warning {
  @apply text-[#FF7347];
}

.keywords-group .keywords-count.limit {
  @apply text-[#B2294B];
}

/* KEYWORD ITEM 
======================================= */
.keyword-item {
  @apply 
    max-h-8
    max-w-full
    text-ellipsis overflow-hidden
    p-2
    flex gap-2 items-center 
    font-bold text-xs
    rounded 
    text-deep-space dark:text-white
    border border-deep-space border-opacity-50
    dark:border dark:border-[#0561b7] dark:border-opacity-50
    bg-[#0561B7] bg-opacity-10
    dark:bg-[#08142C]
    transition-all duration-150 ease-in-out;
}

.keyword-item.hovered,
.keyword-item.focused {
  @apply 
    bg-opacity-20 dark:bg-opacity-50 
    border-deep-space border-opacity-50
    dark:border-[#0561B7] dark:border-opacity-100
    ring-2 ring-deep-space ring-opacity-50 
    dark:ring-[#0561B7] dark:ring-opacity-100;
}