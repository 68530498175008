.companies-page {
  @apply 
    min-h-[calc(100vh-7rem-66px)]
    relative 
    flex flex-col
    border border-transparent
}

.companies-page .page-header {
  @apply flex flex-row gap-4;
}

.companies-page .page-header .col-1 {
  @apply flex-1;

}

.companies-page .page-header .col-1 h1 {
  @apply inline-block text-3xl md:text-4xl font-semibold;
}


.companies-page .page-header .col-2 {
  @apply flex justify-end gap-4;
}


.companies-page .page-header .header-btn {
  @apply
    md:min-w-[56px] 
    aspect-square md:aspect-auto
    flex justify-center md:justify-between items-center md:gap-4
}

.companies-page .page-header .header-btn:hover > svg {
  @apply bg-opacity-0;
}

.company-header {
  @apply 
    mb-4 
    grid gap-4;

  grid-template-columns: auto 32px 1fr;
}