.acnu-feasibility {
  @apply space-y-4 md:space-y-8
}

.acnu-feasibility h1 {
  @apply text-2xl font-sans font-semibold;
}

.acnu-feasibility p {
  @apply 
    text-lg
}

/* PAGE HEADER */
.acnu-feasibility .page-header {
  @apply flex flex-col gap-2;
}

.acnu-feasibility .page-header .acnu-section {
  @apply block md:flex justify-end flex-1;
}

.acnu-feasibility .page-header .acnu-section a {
  @apply 
  px-4 py-3 
  rounded-sm 
  font-bold 
  font-sans
  flex justify-center items-center gap-4 
  border border-[#0561B7] border-opacity-[0.25]
  dark:border-[#0561B7] dark:border-opacity-50
  bg-[#FCFCFC]
  dark:bg-[#0561B7] dark:bg-opacity-10
  cursor-pointer
  transition-all duration-200 ease-in-out
  text-black text-opacity-70
  dark:text-white dark:text-opacity-100
  hover:bg-[#0561B7] hover:bg-opacity-10
  hover:no-underline
  hover:dark:bg-opacity-50;
}

.acnu-feasibility .page-header .acnu-section button {
  @apply bg-gray-500 text-white rounded-sm px-4 py-3 font-bold flex justify-center items-center gap-4 cursor-not-allowed;
}

/* SAFETY AND TOLERABILITY */
.acnu-feasibility .safety-tolerability .info-container {
  @apply p-2 flex flex-col gap-2;
}

@media screen and (min-width: 768px) {
  .acnu-feasibility .safety-tolerability .info-container {
    display: grid;
    grid-template-rows: 82px 1fr;
    grid-template-columns: repeat(6, minmax(0, 1fr));

  }
}

.acnu-feasibility .safety-tolerability .text-summary {
  @apply 
    p-2 md:p-4
    row-start-2
    md:col-span-4
    space-y-2 
    flex flex-col
}

/* STOP USE */
.acnu-feasibility .stop-use .info-container ol {
  @apply list-decimal px-4 space-y-4;
}

/* CHART TWEAKS */
#keywords-cell.highcharts-dashboards-cell > .highcharts-dashboards-component,
#dependence-cell-0.highcharts-dashboards-cell > .highcharts-dashboards-component,
#cell-0-tolerability-chart-container.highcharts-dashboards-cell > .highcharts-dashboards-component {
  margin: 0;
}