/* STOCK INFO
======================================= */
.stock-info-container {
  @apply w-full;
}

.stock-info {
  @apply 
    p-2 md:p-4 
    flex flex-col;
}

.stock-info .ticker {
  @apply text-sm font-semibold;
}

.stock-info .price {
  @apply 
    my-2 
    text-3xl 
    font-bold;
}

.stock-info .historical {
  @apply 
    leading-3 
    text-sm 
    flex items-center gap-1
}

.stock-info .historical h3 {
  @apply 
    font-normal 
    text-sm 
    text-black text-opacity-50;
}

.stock-info .price-info .price {
  @apply 
    text-3xl 
    font-bold;
}

.stock-info .price-info .currency {
  @apply uppercase;
}

.stock-info .historical .change {
  @apply 
    text-sm 
    rounded-lg 
    font-bold;
}

.stock-info .historical .change.positive {
  @apply text-[#2DB300];
}

.stock-info .historical .change.positive::before {
  content: "▲ ";
}

.stock-info .historical .change.negative {
  @apply text-[#B2294B];
}

.stock-info .historical .change.negative::before {
  content: "▼ ";
}

.company-stock-chart .highcharts-background {
  fill: transparent;
}

.company-stock-chart .highcharts-point {
  @apply stroke-black dark:stroke-white
}

.stock-chart {
  @apply 
    w-full
    relative 
    border border-[var(--border-color)]
    flex-1;
}

.stock-chart .highcharts-container {
  width: 100% !important;
  height: 100% !important;
  max-width: none !important;
}

/* STOCK PERFORMANCE
======================================= */
.stock-performance {
  @apply 
    p-4
    grid
    grid-rows-6 grid-cols-1 md:grid-rows-2 md:grid-cols-3
    row-start-3 row-end-4 md:row-start-2 md:row-end-3
    col-start-1 col-end-11;

  gap: 1rem 4vw;

}

.stock-performance h3 {
  @apply font-semibold
}

.perf {
  @apply flex items-center justify-between;
}

.perf h2 {
  @apply 
    m-0 
    text-base 
    text-black dark:text-white
    font-semibold;
}