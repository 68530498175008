.search-spend-rec .search-spend-content {
  @apply
  grid grid-cols-4 gap-2
  rounded
}

.search-spend-rec .highcharts-dashboards-component.highcharts-dashboards-component-highcharts {
  @apply m-0;
}

.search-spend-rec .search-spend-content .search-spend-col {
  @apply h-full w-full 
  rounded overflow-hidden;
}

.search-spend-rec .search-spend-content .col-1 {
  @apply col-span-full;
}

.search-spend-table-content.dataGrid-container {
  /* @apply mt-[5px] */
}

.search-spend-table .search-spend-table-content .highcharts-datagrid-container {
  @apply text-xs lg:text-sm rounded;
}
.highcharts-datagrid-column-headers {
  @apply bg-[#F5F5F5] bg-opacity-100 
  dark:bg-[#0561B7] dark:bg-opacity-10
}

.highcharts-datagrid-container {
  @apply border-[#0561B7] border-opacity-[0.25] dark:border-[#0561B7] dark:border-opacity-50
}

.highcharts-datagrid-outer-container {
  @apply bg-transparent
}

.highcharts-datagrid-inner-container {
  @apply grid
}

.highcharts-datagrid-row {
  @apply bg-transparent 
  even:bg-black even:bg-opacity-5
  even:dark:bg-white even:dark:bg-opacity-5
}

.highcharts-datagrid-cell {
  @apply border-[#0561B7] border-opacity-[0.25] dark:border-[#0561B7] dark:border-opacity-50
}

.highcharts-datagrid-row.hovered {
  @apply bg-black bg-opacity-10
  dark:bg-white dark:bg-opacity-20
}

.highcharts-datagrid-column-header {
  @apply border-b border-[#0561B7] border-opacity-[0.25] dark:border-[#0561B7] dark:border-opacity-50
}