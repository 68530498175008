.company-page {
  @apply container mx-auto space-y-4;
}

.company-page .page-section > p {
  @apply 
    text-base
    text-[var(--primary-text-color)]
    md:text-lg;
}

.company-page .page-header {
  @apply mb-4 flex flex-col;
}

.company-page .stock-info-container {
  @apply p-2 md:p-4
}

/* PROJECTS
================================ */
.company-page .projects {
  @apply pt-2
}

/* NEWS
================================ */
.company-page .news {}

.company-page .news h2 {
  @apply mb-2;
}