.forecast-branded h3 {
  @apply font-bold p-4 text-xl;
}

.forecast-branded h4 {
  @apply 
    text-center font-bold tracking-wide
    bg-[#F5F5F5] bg-opacity-100 
  dark:bg-[#0561B7] dark:bg-opacity-10
}

.forecast-branded .content p {
  @apply text-3xl pb-4
}

.forecast-branded .content {
  @apply h-[400px] 
  grid grid-cols-3 p-2 gap-2
  rounded
}

.forecast-branded .highcharts-dashboards-component.highcharts-dashboards-component-highcharts {
  @apply m-0;
}

.forecast-branded .content .col {
  @apply h-full w-full 
  rounded overflow-hidden
}

.forecast-branded .content .col-1 {
  @apply flex flex-col;
}

.forecast-branded .content .col-2 {
  @apply flex flex-col col-span-2;
}