.company-add-edit .page-header {
  @apply 
    flex items-center gap-4;
}

.company-add-edit h1 {
  @apply text-xl;
}

.company-add-edit form {
  @apply p-4 md:p-8 space-y-4 flex flex-col items-center;
}

.company-add-edit form .form-group {
  @apply w-full max-w-[500px] flex flex-col;
}

.company-add-edit form .form-group label {
  @apply text-sm mb-2;
}

.company-add-edit form .form-group input {
  @apply py-2 px-4 rounded text-lg;
}

.company-add-edit form button {
  @apply 
  font-bold 
  rounded
  bg-[#F814BF] hover:bg-pink-600
  text-lg
  text-white;
}