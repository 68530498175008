/* the accessibility element gets in the way stop mouse interation */
#keywords-bar-container .highcharts-a11y-proxy-container-after {
  pointer-events: none !important;
}

#keywords-bar-container .highcharts-a11y-proxy-container-after button:focus-visible,
#keywords-bar-container .highcharts-a11y-proxy-container-after button:focus {
  box-shadow: none !important;
}

#keywords-pie-cell .highcharts-title {
  font-size: 16px !important;
  font-weight: bold !important;
}