.interface {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  font-family: "Bebas Neue", cursive;
}

/**
 * Time
 */
.time {
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  color: #ffffff;
  font-size: 6vh;
  background: #00000033;
  padding-top: 5px;
  text-align: center;
}

/**
 * Restart
 */
.restart {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  color: #ffffff;
  font-size: 80px;
  background: #00000033;
  padding-top: 10px;
  pointer-events: auto;
  cursor: pointer;
}

/**
 * Controls
 */
.game-controls {
  position: absolute;
  bottom: 2%;
  left: 2%;
  /* width: 100%; */
}

.game-controls .raw {
  display: flex;
  justify-content: center;
}

.game-controls .key {
  width: 40px;
  height: 40px;
  margin: 4px;
  border: 2px solid #ffffff;
  border-radius: 2px;
  background: #ffffff44;
}

.game-controls .key.large {
  width: 136px;
}

.game-controls .key.active {
  background: #ffffff99;
}
