.project-page {
  @apply space-y-4 md:space-y-8
}

.project-page h1 {
  @apply text-2xl font-sans font-semibold;
}

.project-page p {
  @apply 
    text-lg
}

.project-page .overview {
  @apply flex flex-col;
}

/* @media md and up */
@media (min-width: 768px) {
  .project-page .overview {
    grid-template-columns: 200px repeat(5, 1fr);
    grid-template-rows: 200px auto auto;
  }
}

.project-page .overview-section .highcharts-dashboards-component.highcharts-dashboards-component-highcharts {
  @apply m-0;
}