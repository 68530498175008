/* Overlay container */
.lightbox-overlay {
  @apply fixed inset-0 bg-white bg-opacity-80 dark:bg-black dark:bg-opacity-90 
    backdrop-blur-lg dark:backdrop-blur-lg mx-auto overflow-scroll z-[50];
}

/* Close button */
.lightbox-close-btn {
  @apply fixed top-4 right-4 rounded-full h-10 w-10 text-lg 
    hover:bg-white hover:bg-opacity-20;
}

/* Main content wrapper */
.lightbox-content {
  @apply w-[90%] py-12 mx-auto space-y-12 min-h-full;
}

.lightbox-content .section-header h2 {
  @apply text-deep-space dark:text-[#C0D7ED]
}

.lightbox-content .section-header p {
  @apply ml-2 px-2 border-l border-watermelon-500 mt-3 font-normal text-xl text-deep-space dark:text-white;
}

/* Example for a 200px x 200px image with certain styling: */
.lightbox-main-img {
  @apply h-[200px] w-[200px] rounded-md object-cover;
}