/* a loading spinner overlay component */
.loading {
  @apply 
    fixed inset-0
    h-full w-full
    bg-black bg-opacity-50
    grid place-items-center;

  z-index: 9999;
}

.loading .spinner {
  @apply 
    w-20 h-20
    border-8 border-gray-50
    border-t-[#0561B7] dark:border-t-watermelon-500
    rounded-full
    animate-spin;
}