.swot-graphic .swot-text {
  @apply text-center;
}

.swot-graphic .swot-text h3 {
  @apply mb-4 text-2xl uppercase tracking-widest;
}

.swot-graphic .swot-text ul {
  @apply space-y-2 text-lg list-none;
}
