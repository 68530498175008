.summary {
  @apply space-y-2;
}

.summary .fallback {
  @apply text-center px-0 py-8 md:p-8;
}

.feedback-buttons {
  @apply flex justify-end gap-2
}

.feedback-buttons button {
  @apply 
  h-[34px] w-[34px] 
  border border-[#0561B7] border-opacity-[0.25] dark:border-opacity-50 p-1 rounded-full ;
}

.feedback-buttons .up {
  @apply hover:border-[#2DB300] hover:text-[#2DB300] hover:bg-[#2DB300] hover:bg-opacity-20
}

.feedback-buttons .down {
  @apply hover:border-[#B2294B] hover:text-[#B2294B] hover:bg-[#B2294B] hover:bg-opacity-20
}

.feedback-buttons .feedback-spinner {
  @apply h-[34px] w-[34px] p-1 rounded-full;
}

.feedback-thanks {
  @apply w-full inline-block text-right text-xs font-bold self-end leading-[34px];
}

.feedback-buttons, .feedback-thanks {
  @apply min-h-[34px];
}