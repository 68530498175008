.acnu-dashboard-page .charts {
  @apply container mx-auto space-y-4 lg:space-y-0 grid grid-rows-2 grid-cols-1 lg:grid-rows-1 lg:grid-cols-3 gap-4;
}

.acnu-dashboard-page .charts .chart {
  @apply relative w-full p-4 lg:p-1;
}

.acnu-dashboard-page .charts .chart {
  @apply col-span-full
}

.acnu-dashboard-page .charts .pie {
  @apply col-span-1 lg:col-span-1;
}

/* #acnu-dashboard .highcharts-dashboards-component.highcharts-dashboards-component-kpi,
#acnu-dashboard .highcharts-dashboards-component.highcharts-dashboards-component-highcharts {
 @apply bg-white bg-opacity-5
} */

#acnu-row-00 .highcharts-dashboards-component.highcharts-dashboards-component-highcharts {
  height: 650px;
}

@media (max-width: 768px) {
  #acnu-row-00 .highcharts-dashboards-component.highcharts-dashboards-component-highcharts {
    height: 400px;
  }
}