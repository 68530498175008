.keyword-universe-page {
  @apply space-y-4 md:space-y-8
}

.keyword-universe-page h1 {
  @apply text-2xl font-sans font-semibold;
}

/* PAGE HEADER */
.keyword-universe-page .page-header {
  @apply p-0 mb-4 flex flex-col md:flex-row md:gap-4 md:items-center col-span-1
}



.keyword-universe-page .page-header .acnu-section {
  @apply block md:flex flex-col items-end flex-1;
}

.keyword-universe-page .page-header .acnu-section button {
  @apply bg-gray-500 text-white rounded-sm px-4 py-3 font-bold flex justify-center items-center gap-4 cursor-not-allowed;
}