.admin-page.keyword-profile .info {
  @apply 

    grid grid-cols-3 md:grid-cols-6
    gap-2 md:gap-4
}

.admin-page.keyword-profile .info .info-item {
  @apply 
    flex flex-col gap-1
    aspect-square
}

.admin-page.keyword-profile .info .info-item h2 {
  @apply lg:text-xl font-semibold text-center;
}

.admin-page.keyword-profile .info .info-item p {
  @apply lg:text-xl flex-1 text-center content-center;
}