.label-group {
  @apply
    relative 
    mb-4
    flex items-center justify-between
    text-black dark:text-white;
}

.label-group label {
  @apply text-sm;
}

.label-group svg {
  @apply ml-2 cursor-pointer;
}

.label-group .range-data {
  @apply font-bold text-lg;
}

.label-group .tooltip {
  @apply 
    absolute top-full left-0
    w-full max-w-[350px]
    p-4
    rounded
    text-sm
    border border-black border-opacity-30
    dark:border-white dark:border-opacity-30
    bg-white bg-opacity-80
    dark:bg-black dark:bg-opacity-90
    shadow-md dark:shadow-none
    z-10;

    backdrop-filter: blur(5px) saturate(150%);
}