.top-questions {
  @apply flex flex-col p-2;
}

.filter.filter1-keys {
  @apply grid grid-rows-3 grid-cols-3 md:grid-cols-9 md:grid-rows-1
}

.filter.filter2-keys {
  @apply grid grid-cols-3 grid-rows-3 md:grid-cols-9 md:grid-rows-1
}

.filter div > label {
  @apply relative h-full w-full min-h-[40px] px-2 py-4 font-bold grid place-items-center text-center text-xs lg:text-base capitalize cursor-pointer;
}

/* style the label that comes immediately after the selected radio button */
.filter input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  inset: 2px;
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  border: 2px solid currentColor;
}

.filter label.otc {
  @apply uppercase
}

.filter input {
  @apply hidden
}

.top-questions .dot {
  @apply w-3 h-3 inline-block
}

.Condition {
  @apply bg-red-500 text-black;
}

.Lifestyle {
  @apply bg-[#9169F6] text-black;
}

.Symptoms {
  @apply bg-[#539196] text-black;
}

.Diagnosis {
  @apply bg-[#ED9EB9] text-black;
}

.Treatment {
  @apply bg-[#F1C299] text-black;
}

.Brand {
  @apply bg-[#F6DFAC] text-black;
}

.Side.Effects {
  @apply bg-[#CAD7C6] text-black;
}

.OTC {
  @apply bg-[#E2FBC3] text-black;
}

.when {
  @apply bg-[#6CBDDD] text-black;
}

.where {
  @apply bg-[#CE67BA] text-black;
}

.can {
  @apply bg-[#E9CD62] text-black;
}

.is {
  @apply bg-[#9867FE] text-black;
}

.who {
  @apply bg-[#83D5B5] text-black;
}

.should {
  @apply bg-[#5572EE] text-black;
}

.which {
  @apply bg-[#DA5E56] text-black;
}

.what {
  @apply bg-[#A6A6A7] text-black;
}

.how {
  @apply bg-pink-300 text-black;
}