.demographic-opportunity h3 {
  @apply font-bold p-4 text-xl;
}

.demographic-opportunity h4 {
  @apply p-2
    text-center font-bold tracking-wide
    bg-[#F5F5F5] bg-opacity-100 
    dark:bg-[#0561B7] dark:bg-opacity-10
}

.demographic-opportunity .content .col > p {
  @apply text-xl p-4 bg-transparent
}

.demographic-opportunity .content {
  @apply
  grid grid-cols-4 gap-2
  rounded
}

.demographic-opportunity .content .col {
  @apply h-full w-full 
  rounded overflow-hidden;
}

.demographic-opportunity .content .col-1 {
  @apply flex flex-col
}

.demographic-opportunity .content .col-2 {
  @apply col-span-3
}

.demographic-opportunity #demographic-opportunity-container .highcharts-data-label text {
  stroke-width: 2px;
  stroke: #000;
  fill: #fff;
  paint-order: stroke fill;
}