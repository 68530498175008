.project-keywords {
  @apply space-y-4 md:space-y-8;
}


.keyword-search form {
  @apply flex gap-2;
}

.keyword-search form input[type="text"] {
  @apply 
    w-full 
    px-4 py-2 
    rounded-l-lg
    border 
    border-transparent border-opacity-100
    dark:border-white dark:border-opacity-20
    bg-white bg-opacity-10;
}

.keyword-search form button {
  @apply 
    p-3 
    flex items-center justify-center
    bg-[length:200%_auto]
    bg-[#F4D0A5] dark:bg-[#DE7F8E]
    bg-gradient-to-r 
    from-[#FDA7CB] via-[#FDABA9] to-[#FBC6AA]
    dark:from-[#4900E1] dark:via-[#7C20D8] dark:to-[#F4D0A5]
    rounded-r-lg
    text-black dark:text-white
    cursor-pointer
    transition-all duration-200 ease-in-out
    hover:bg-right;
}

.project-keywords .insights > div {
  @apply section-bg-light dark:section-bg-dark p-2 md:p-4
}

.project-keywords #search-volume-chart .highcharts-background {
  @apply fill-transparent;
}