[data-theme="light"] .genai {
  --range-bg: #FFFFFF;
  --range-border: 0.3px solid #272525;
  --btn-bg-hover: rgba(0, 0, 0, 0.1);
  --btn-border-hover: rgba(0, 0, 0, 0.5)
}

[data-theme="dark"] .genai {
  --range-bg: var(--primary-text-color);
  --range-border: 1px solid var(--primary-text-color);
  --btn-bg-hover: rgba(255, 255, 255, 0.1);
  --btn-border-hover: rgba(255, 255, 255, 0.5)
}

.genai {
  @apply space-y-4;
}

.genai .page-header {
  @apply text-3xl;
}

.genai .page-content form {
  @apply 
    grid 
    grid-cols-1 
    gap-x-4 gap-y-8;
}

@media screen and (min-width: 768px) {
  .genai .page-content form {
    grid-template-columns: 1fr 0.5fr;
    grid-template-rows: repeat(6, 1fr);
  }
}

.genai .page-content form button[type="submit"] {
  @apply 
    w-full max-w-none;
}

.genai .form-group select {
  @apply 
    px-4 py-2
    rounded-sm
    border border-black border-opacity-30
  dark:border-white dark:border-opacity-30;
}

.genai .form-group textarea {
  @apply 
    h-full
    p-4
    rounded-sm
    bg-white bg-opacity-30
    dark:bg-black dark:bg-opacity-30
    border border-black border-opacity-30
    dark:border-white dark:border-opacity-30
    resize-none;
}

.genai .form-group.prompt {
  @apply 
    relative
    md:mt-0 md:h-full
    row-start-7 row-span-1 col-start-1 col-span-1
    md:col-start-1 md:row-start-1
    md:row-end-7 md:col-end-2;
}

.genai .form-group.prompt .dictation-btn {
  @apply 
    absolute
    bottom-4 right-4
    h-10 w-10
    rounded-full
    bg-white bg-opacity-0 hover:bg-opacity-10
    border 
    border-black border-opacity-30 hover:border-opacity-100
    dark:border-white dark:border-opacity-30 dark:hover:border-opacity-100
    cursor-pointer;
}

.genai .form-group.button-group {
  @apply
    flex flex-row gap-4
    row-start-8 row-span-1
    md:row-start-7 md:col-span-2;
}

.genai .form-group.button-group button {
  @apply bg-[#DE7F8E] bg-gradient-to-r from-indigo-700 via-purple-500 to-pink-500;
}

/* SLIDER
======================================= */
.genai input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  background-color: var(--input-bg);
  @apply border border-[#e5e0df] dark:border-[#333];
}

.genai input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.genai input[type=range]:focus {
  @apply 
    outline-transparent;
}

.genai input[type=range]::-ms-track {
  @apply 
    w-full 
    bg-transparent
    border border-transparent
    text-transparent
    cursor-pointer;
}

/* Special styling for WebKit/Blink */
.genai input[type=range]::-webkit-slider-thumb {
  @apply 
    h-[37px] w-4
    bg-white hover:bg-gray-100
    dark:bg-gray-300 dark:hover:bg-white
    cursor-pointer;

  -webkit-appearance: none;
  border: var(--range-border);
  border-radius: 3px;
  margin-top: -15px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */

}

/* All the same stuff for Firefox */
.genai input[type=range]::-moz-range-thumb {

  border: var(--range-border);
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: var(--range-bg);
  cursor: pointer;
}

/* All the same stuff for IE */
.genai input[type=range]::-ms-thumb {

  border: var(--range-border);
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: var(--range-bg);
  cursor: pointer;
}

.genai input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  background: var(--btn-gradient);
  border-radius: 1.3px;
  border: 0.2px solid var(--primary-text-color);
}

.genai input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--btn-gradient);
}

.genai input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: var(--btn-gradient);
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

.genai input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
.genai input[type=range]::-ms-fill-lower {
  background: var(--btn-gradient);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
}
.genai input[type=range]:focus::-ms-fill-lower {
  background: var(--btn-gradient);
}
.genai input[type=range]::-ms-fill-upper {
  background: var(--btn-gradient);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
}
.genai input[type=range]:focus::-ms-fill-upper {
  background: var(--btn-gradient);
}


/* RESPONSE
======================================= */
.genai .response-content .response-header {
  @apply 
    mb-4
    flex justify-between;
}

.genai .response-content .response-header h2 { 
  @apply 
    text-2xl;
}

.genai .response-content .response-feedback {
  @apply 
    flex gap-2;
}

.genai .response-content .feedback-btn {
  @apply 
    h-9 w-9 
    rounded-full
    bg-white bg-opacity-0 hover:bg-opacity-10 
    border border-black border-opacity-30 hover:border-opacity-100 
    dark:border-white dark:border-opacity-30 dark:hover:border-opacity-100
    cursor-pointer;
}

.genai .response-content .feedback-btn[aria-label="thumbs up"] {
  @apply 
    hover:text-green-500 
    hover:bg-green-500 hover:bg-opacity-10
    hover:border-green-500 hover:border-opacity-100;
}

.genai .response-content .feedback-btn[aria-label="thumbs down"] {
  @apply 
    hover:text-red-500
    hover:bg-red-500 hover:bg-opacity-10
    hover:border-red-500 hover:border-opacity-100;
}

.genai .response {
  @apply 
    w-full min-h-[500px]
    p-4
    rounded-sm
    bg-white bg-opacity-30
    dark:bg-black dark:bg-opacity-30
    border border-black border-opacity-30
    dark:border-white dark:border-opacity-30
    font-mono;
}

.genai .response pre {
  text-wrap: wrap;
}