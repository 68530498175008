.progress {
  @apply flex-1
}

.progress label {
  @apply block font-semibold
}

.progress .progress-container {
  @apply grid place-items-center
}

.progress .progress-value {
  @apply 
  col-start-1 row-start-1 font-bold tracking-widest text-lg;
}

.progress .progress-bar {
  height: var(--progress-height, 1rem);
  @apply 
    w-full
    rounded
    overflow-hidden 
    col-start-1 row-start-1 
    border border-deep-space border-opacity-[0.25]
    dark:border-white
    bg-[#0561B7] bg-opacity-5 dark:bg-transparent
    appearance-none;
}

/* Progress bar styling for WebKit browsers (Chrome, Safari) */
.progress-bar::-webkit-progress-bar {
  background-color: transparent;
}

.progress-bar::-webkit-progress-value {
  @apply 
    bg-[#0561B7]
    dark:bg-gradient-to-r 
    dark:from-[#E75027] dark:to-[#f814bf];

  /* background: linear-gradient(90deg, #E75027, #f814bf); */
}

/* Progress bar styling for Firefox */
.progress-bar::-moz-progress-bar {
  @apply 
    bg-gradient-to-r 
    from-[#0561B7] to-[#C0D7ED]  
    dark:from-[#E75027] dark:to-[#f814bf];
}