@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;600;700&family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@import url("https://code.highcharts.com/dashboards/css/dashboards.css");
/* @import url("https://code.highcharts.com/css/highcharts.css"); */
@import url("https://code.highcharts.com/datagrid/css/datagrid.css");

@layer utilities {
  .fpo {
    position: relative;
  }
  .fpo::after {
    content: "FPO";
    position: absolute;
    top: 0;
    left: 50%;
    color: #c00;
    padding: 0.25em;
    font-size: 1.2rem;
    font-weight: bold;
    transform: translateX(-50%) translateY(-10px) rotate(-35deg);
    z-index: 40;
    opacity: 0.7;
    text-shadow: 
    1px 1px 0 #fff,  /* Right bottom */
    -1px -1px 0 #fff, /* Left top */
    -1px 1px 0 #fff,  /* Left bottom */
    1px -1px 0 #fff;  /* Right top */
  }
}

@layer components {
  .page-base {
    @apply p-2 pb-8 md:px-8 md:py-12 md:pb-16;
  }

  .page-section {
    @apply section-bg-light dark:section-bg-dark
  }

  .section-header {
    @apply 
      p-4 
      font-semibold text-2xl
      bg-[#F5F5F5] bg-opacity-100 
      dark:bg-[#0561B7] dark:bg-opacity-10 
      text-deep-space dark:text-[#C0D7ED] 
      rounded-t
  }

  /* appied to anchor tags as well as buttons */
  .btn {
    @apply     
    p-4
    font-sans font-bold
    rounded
    text-center
    cursor-pointer
    transition-all duration-200 ease-in-out
    outline-transparent
    border 
    border-[#0561B7] border-opacity-25
    dark:border-[#0561B7] dark:border-opacity-50
    text-deep-space text-opacity-70
    dark:text-white dark:text-opacity-100
    bg-[#FCFCFC] dark:bg-[#0561B7] dark:bg-opacity-10
    hover:bg-[#0561B7] hover:bg-opacity-10
    hover:dark:bg-opacity-50
    hover:ring-1 hover:ring-[#0561B7] hover:ring-opacity-50
    hover:dark:ring-opacity-100
    hover:text-deep-space dark:hover:text-white
    focus-visible:text-deep-space dark:focus-visible:text-white
    hover:no-underline
    focus-visible:bg-[#0561B7] 
    focus-visible:bg-opacity-10
    focus-visible:dark:bg-opacity-50
    focus-visible:ring 
    focus-visible:ring-[#0561B7] focus-visible:ring-opacity-50
    focus-visible:dark:ring-opacity-100
    disabled:ring-0
    disabled:opacity-40 disabled:cursor-not-allowed
  }

  .form-group {
    @apply w-full flex flex-col border 
    border-[#0561B7] border-opacity-30 dark:border-opacity-50
    rounded;
  }
  
  .form-group:has(textarea:focus),
  .form-group:has(select:focus),
  .form-group:has(input:focus) {
    @apply outline-none ring-2 ring-[#0561B7] ring-opacity-100
  }
  
  .form-group label {
    @apply bg-black dark:bg-[#0561B7] bg-opacity-[2.5%] dark:bg-opacity-5 py-1.5 px-3;
  }
  
  .form-group.focused label {
    @apply bg-opacity-5 dark:bg-opacity-10
  }
}


/* highcharts overrides */
.highcharts-dashboards-wrapper {
  @apply p-0
}

.highcharts-dashboards, .highcharts-dashboards-wrapper,
.highcharts-dashboards-cell > .highcharts-dashboards-component {
  @apply bg-transparent shadow-none
}

.highcharts-exporting-group .highcharts-button-box {
  @apply
    fill-transparent 
    stroke-transparent
}

.highcharts-exporting-group .highcharts-button-symbol {
  @apply 
  fill-deep-space stroke-deep-space 
  dark:fill-white dark:stroke-white
}

/* 
highcharts accessibility buttons often get in the way of desired chart functionality
buttons are hidden so no need for them to be mouse interactive
*/
.highcharts-a11y-proxy-element {
  @apply pointer-events-none
}

:root {
  --pink-rgb: 248, 20, 191;
  --input-bg: rgba(0, 0, 0, 0.3);
  --gradient: linear-gradient(
    68deg, 
    #FF3C6D, 
    #FF8F6E
  );

  interpolate-size: allow-keywords;
}

/* in data-theme="light" define light theme */
[data-theme="light"] {
  --secondary-color: #F814BF;
  --border-color: #333;
  --background-image: url('./assets/img/bg-light.png');
  --background-color-overlay: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
  --input-bg: rgba(255, 255, 255, 0.3);
  --link-color: #401957;
  --link-color-hover: #27043c;

  --header-gradient: linear-gradient(145deg, #BB74FF 10%, #FB6029 90%);

  --gradient: linear-gradient(
    68deg, 
    #C0D7ED,
    #0561B7
  );
}

[data-theme="dark"] {
  --secondary-color: #F814BF;
  --border-color: #FFFFFF;
  --background-image: url('./assets/img/bg-dark.png');
  --background-color-overlay: linear-gradient(rgba(0, 0, 10, 0.8), rgba(0, 0, 10, 0.8));
  --link-color: #f1e6ff;
  --link-color-hover: #b477ff;
  --header-gradient: linear-gradient(91.78deg, #DF7A6C 1.24%, #9D20C9 73.37%, #3300D9 166.1%);
  --gradient: linear-gradient(
    68deg, 
    #FF3C6D, 
    #FF8F6E
  );
}

body {
  @apply 
    m-0 
    text-deep-space dark:text-white
    bg-white dark:bg-[#020021]
    font-inter;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: var(--background-color-overlay), var(--background-image);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; */
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

a {
  @apply 
    rounded
    outline-none
    hover:underline focus-visible:underline
    hover:text-[#0561B7] hover:dark:text-watermelon-500
    focus-visible:text-[#0561B7] focus-visible:dark:text-watermelon-500
    focus-visible:ring 
    focus-visible:ring-[#0561B7] focus-visible:ring-opacity-50
    focus-visible:dark:ring-opacity-100
}

button {
  @apply 
  rounded
  cursor-pointer
  focus-visible:ring 
  focus-visible:ring-[#0561B7] focus-visible:ring-opacity-50
  focus-visible:dark:ring-opacity-100
  disabled:ring-0
  disabled:opacity-40 disabled:cursor-not-allowed
}

button:focus-visible {
  outline: transparent;
}

code {
  @apply font-mono;
}

h1, h2, h3, h4 {
  @apply font-sans text-deep-space dark:text-white;
}

input,
textarea,
select {
  @apply 
    p-4 
    rounded-sm
    bg-white bg-opacity-50
    dark:bg-black dark:bg-opacity-30
    dark:text-white
    transition-shadow duration-200 ease-in-out
    outline-none;
}

select {
  @apply appearance-none pr-4 outline-none;

  background-image:
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="5"><path fill="grey" d="M0 0h8L4 5z"/></svg>');
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  background-size: 8px 5px;
}

.form-group select {
  @apply min-h-[60px]
}

input[type="text"] {
  @apply min-h-[60px]
}

p {}

ul {
  @apply 
    m-0 p-0
    list-none;
}

canvas {
  @apply touch-none
}

section {
  /* @apply  */
    /* p-4 md:p-8  */
    /* m-auto */
    /* rounded */
    /* bg-white bg-opacity-70 */
    /* dark:bg-slate-950 dark:bg-opacity-75 */
    /* border  */
    /* border-transparent */
    /* dark:border-white dark:border-opacity-10; */

}

.form-group {
  @apply flex flex-col;
}



.background {
  @apply bg-section-light dark:bg-section-dark shadow-lg dark:shadow-none
}

/* @keyframes fade in animation 500ms */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}