.missing {
  @apply 
    container mx-auto
    px-4 py-16
    bg-white bg-opacity-70
    dark:bg-slate-950 dark:bg-opacity-75
    border border-transparent
    dark:border-white dark:border-opacity-10
    rounded
    filter backdrop-blur-2xl backdrop-saturate-150;
}

.missing h1 {
  @apply mb-8 text-4xl text-center
}

.missing p {
  @apply text-lg md:text-center;
}

.missing a {
  @apply text-purple-500 font-semibold hover:underline;
}