.swot-analysis > .swot-text p {
  @apply relative p-4 
    text-xl font-sans tracking-widest
    uppercase;
}

.swot-analysis > .swot-text p::before {
  @apply content-[""]
    inline-block w-4 h-4 mr-2
    rounded-full;
}

/* STRENGTH */
.swot-analysis > .swot-text p:first-of-type::before {
  @apply bg-[#CDBBF4] dark:bg-[#9169F6];
}

/* WEAKNESS */
.swot-analysis > .swot-text p:nth-of-type(2)::before {
  @apply bg-[#E9DCBC] dark:bg-[#539196];
}

/* OPPORTUNITY */
.swot-analysis > .swot-text p:nth-of-type(3)::before {
  @apply bg-[#020001] dark:bg-[#F1C299];
}

/* THREAT */
.swot-analysis > .swot-text p:last-of-type::before {
  @apply bg-[#D65B37] dark:bg-[#EE9FBA];
}

.swot-analysis > .swot-text ul {
  @apply space-y-4 px-4 mb-4 last:mb-0 md:text-lg;
}
