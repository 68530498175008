.grid-container {
  @apply 
    w-full
    grid
    items-center
    border border-[#5c5c5c]
    text-sm md:text-base;

  grid-template-columns: 2fr 1fr 1fr 1fr 2fr; /* Adjust these values to match your desired column widths */
}

.grid-container.universe-category {
  @apply overflow-x-scroll overflow-y-hidden;
  grid-template-columns: 2fr auto repeat(6, 1fr);
}

.grid-container.forecast-category {
  @apply overflow-x-scroll overflow-y-hidden;
  grid-template-columns: 2fr repeat(9, 1fr);
}

.grid-item {
  @apply p-2 py-0 md:p-4 md:py-0;
}

.header {
  @apply 
    min-h-[50px] md:min-h-[70px]
    font-bold
    flex items-center
    text-xs lg:text-base
    text-black dark:text-white
    whitespace-nowrap;
}

.grid-item.data {
  @apply md:text-lg min-h-[65px] flex items-center border-r border-[#5c5c5c];
}

.estimated-cost-monthly.grid-item.data,
.spark.grid-item.data {
  @apply border-r-0;
}

.grid-item.data.subtotal {
  @apply 
    font-bold 
    bg-black bg-opacity-10
    dark:bg-white dark:bg-opacity-10;
}

.grid-item.data.total {
  @apply 
    font-bold 
  bg-black bg-opacity-20
  dark:bg-white dark:bg-opacity-20;
}

.grid-container.universe-category .header,
.grid-container.forecast-category .header {
  @apply 
    font-sans text-sm
    bg-[#E75027] bg-opacity-30
    dark:bg-teal-500 dark:bg-opacity-30
    border-b border-[#5c5c5c];
}

.header.center,
.data.center {
  @apply justify-center items-center text-center;
}

.data.right {
  @apply justify-end items-center text-right;
}

.data svg + svg {
  margin-left: 0.5rem;
}

.grid-container .data:not(.total) {
  @apply 
    border-b border-[#5c5c5c]
}