

.search-opportunity h3 {
  @apply font-bold p-4 text-xl
}

.search-opportunity h4 {
  @apply p-2
    text-center font-bold tracking-wide
    bg-[#F5F5F5] bg-opacity-100 
    dark:bg-[#0561B7] dark:bg-opacity-10
}

.search-opportunity .content .col > p {
  @apply text-xl p-4 bg-transparent
}

.search-opportunity .content {
  @apply
  grid grid-cols-4 gap-2
  rounded
}

.search-opportunity .content .col {
  @apply h-full w-full 
  rounded overflow-hidden;
}

.search-opportunity .content .col-1 {
  @apply flex flex-col
}

.search-opportunity .content .col-2 {
  @apply col-span-3
}

.search-opportunity .highcharts-dashboards-component.highcharts-dashboards-component-highcharts {
  @apply m-0;
}