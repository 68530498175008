.news-item {
  @apply 
    h-full 
    flex flex-col
    overflow-hidden
    transition-all
    hover:no-underline
    hover:cursor-pointer hover:scale-105
    hover:text-[#0561B7] hover:dark:text-watermelon-500
    hover:border-[#0561B7] hover:border-opacity-100
    hover:dark:border-watermelon-500 hover:dark:border-opacity-100
}

.news-item:hover h3 {
  @apply 
    transition-all 
    text-[#0561B7] dark:text-watermelon-500;
}

.news-item .news-info {
  @apply flex-1;
}

.news-item h3 {
  @apply font-serif font-semibold;
}

.news-item .news-image {
  @apply 
    bg-white
    aspect-[16/9] 
    overflow-hidden;
}

.news-item img {
  @apply 
    h-full w-full 
    object-cover;
}

.news-item .news-body {
  @apply 
  p-4
  flex flex-col gap-4
  flex-1 
}

.news-item .published,
.news-item .author {
  @apply text-xs
}