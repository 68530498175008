.conditions-list {
  @apply p-2 md:p-4 grid md:grid-cols-2 lg:grid-cols-3 gap-4;
}

.conditions-list__toggle-button {
  @apply text-base py-2 space-x-2
}

.conditions-list__item {
  @apply flex justify-between;
}

.condition-list__item__link {
  @apply flex items-center gap-4;

  &:hover .conditions-list__item__name {
    @apply text-watermelon-500;
  }
}

.conditions-list__item__name {
  @apply text-xl md:text-2xl;
}

.conditions-list__item__button-group {
  @apply flex items-center gap-4 md:gap-8
}