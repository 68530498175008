

.annual-forecast h3 {
  @apply font-bold p-4 text-xl;
}

.annual-forecast h4 {
  @apply 
    text-center font-bold tracking-wide
    bg-[#F5F5F5] bg-opacity-100 
    dark:bg-[#0561B7] dark:bg-opacity-10
}

.annual-forecast .content p {
  @apply text-3xl pb-4
}

.annual-forecast .content {
  @apply md:h-[465px] 
  grid md:grid-cols-3 gap-2
  rounded
}

.annual-forecast .content .col {
  @apply h-full w-full overflow-hidden
}

.annual-forecast .content .col-1 {
  @apply flex flex-col border-r border-[#0561B7] dark:border-opacity-10;
}