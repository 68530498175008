.admin-page.keyword-universe {
  @apply flex flex-col
}

.admin-page.keyword-universe section {

}

.admin-page.keyword-universe .search-form {
  @apply p-4 grid md:grid-cols-6 gap-4;
}

.search-form .form-group.type,
.search-form .form-group.projects,
.search-form .form-group.conditions,
.search-form .form-group.categories {
  @apply 
    col-span-1 md:col-span-2 
}

.search-form .form-group.search {
  @apply col-span-1 md:col-span-5;
}

.search-form .form-group select,
.search-form .form-group input {
  @apply p-4 rounded
    disabled:cursor-not-allowed
    disabled:opacity-50
    placeholder:text-deep-space
    placeholder:dark:text-white;
}

.suggestions-list {
  @apply 
  border border-white 
  dark:border-white dark:border-opacity-30
  bg-white bg-opacity-30
  dark:bg-black dark:bg-opacity-30
    backdrop-blur-xl backdrop-saturate-150
    rounded-b;

  position: absolute;
  top: 100%;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 100;
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestions-list li:hover {
  @apply bg-deep-space bg-opacity-10 dark:bg-white dark:bg-opacity-25
}