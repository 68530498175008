.estimated-spend h3 {
  @apply font-bold p-4 text-xl;
}

.estimated-spend h4 {
  @apply p-2
    text-center font-bold tracking-wide
    bg-[#e75027] bg-opacity-30
    dark:bg-teal-500 dark:bg-opacity-30;
}

.estimated-spend .content .col > p {
  @apply text-xl p-4 bg-transparent
}

.estimated-spend .content {
  @apply
  grid grid-cols-4 gap-2
  rounded
}

.estimated-spend .content .col {
  @apply h-full w-full 
  shadow-lg dark:shadow-none
  rounded overflow-hidden;
}

.estimated-spend .content .col-1 {
  @apply flex flex-col col-span-4
  bg-transparent bg-opacity-30 
  dark:bg-white dark:bg-opacity-5
}

.estimated-spend #estimated-spend-container .highcharts-data-label text {
  stroke-width: 2px;
  stroke: #000;
  fill: #fff;
  paint-order: stroke fill;
}