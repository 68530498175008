.condition-opportunity h3 {
  @apply font-bold p-4 text-xl;
}

.condition-opportunity h4 {
  @apply 
    text-center font-bold tracking-wide
    bg-[#F5F5F5] bg-opacity-100 
    dark:bg-[#0561B7] dark:bg-opacity-10
}

.condition-opportunity > .content .col > p {
  @apply text-3xl pb-4
}

.condition-opportunity > .content {
  @apply h-[450px] 
  grid grid-cols-4 gap-2
  rounded
}

.condition-opportunity > .content .col {
  @apply h-full w-full 
  rounded overflow-hidden
}

.condition-opportunity > .content .col-1 {
  @apply flex flex-col;
}

.condition-opportunity > .content .col-2 {
  @apply col-span-3
}