.warnings-container {
  @apply 
    grid grid-cols-2 md:grid-cols-3
    grid-rows-2  md:grid-rows-1 
    col-span-1 md:col-span-4
    gap-2;
}

.warnings-container .info-box {
  @apply 
    max-h-[82px]
    col-span-1 
    p-2 px-5
    flex gap-2 items-center
}

.warnings-container .info-box .content {
  @apply text-center md:text-right flex-1 flex flex-col gap-2;
}

.warnings-container .info-box .content h3 {
  @apply font-bold text-sm;
}

.warnings-container .info-box .content p {
  @apply text-3xl text-deep-space dark:text-white flex-1 font-bold;
}

.warnings-container .info-box:last-child {
  @apply col-span-2 md:col-span-1;
}