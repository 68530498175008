.admin-page.condition-profile {
  @apply flex flex-col
}

.admin-page.condition-profile section ol {
  @apply space-y-2 list-decimal px-4 mb-5;
}

.tab-group {
  @apply p-2 grid grid-cols-4 gap-2 text-center;
}

.tab-group .tab { 
  @apply 
    relative
    p-4 
    rounded 
    cursor-pointer 
    border border-[var(--color)] 
   text-black dark:text-white
    z-0;
}

.tab-group .tab h3 {
  @apply z-10;
}

.tab-group .tab .bg {
  @apply 
    absolute 
    inset-0 
    bg-[var(--color)]
    opacity-10
    rounded 
    pointer-events-none
    z-[-1];
} 

.tab-group .tab.active {
  @apply font-bold
}

.tab-group .tab.active .bg {
  @apply opacity-30
}

.tab-content {}

.tab-content .tab-pane {
  @apply rounded min-h-[450px] hidden;
}

.tab-content .tab-pane.active {
  @apply 
    grid place-items-center 
    text-2xl font-bold
    bg-white bg-opacity-10
    dark:bg-black dark:bg-opacity-10;
}