.keyword-universe-table {
  @apply relative w-full min-w-[1024px] 
}

.keyword-universe-table thead th {
  @apply p-4 max-w-[200px]
}

.keyword-universe-table tbody td {
  @apply p-4 max-w-[200px]
}