.sign-in {
  --sign-in-height: calc(100% - 66px);

  @apply 
    fixed bottom-0 left-0 right-0
    w-full
    m-auto
    flex items-end justify-center
    z-50;
}



.sign-in .btn-container {
  @apply 
    h-[100px] w-full
    flex justify-center items-center
    rounded-sm pointer-events-auto
    bg-white bg-opacity-50
    dark:bg-black dark:bg-opacity-40;

  backdrop-filter: blur(10px) saturate(170%);

}

.sign-in .btn-container .btn {
  --btn-border-offset: -20px;
  @apply 
    relative 
    w-[220px] 
    p-2
    flex justify-center items-center 
    rounded-none
    border-none
    ring-0
    bg-white bg-opacity-100
    dark:bg-watermelon-500 dark:bg-opacity-20
    hover:dark:bg-opacity-40;
}

.btn .btn-border {
  @apply absolute
  h-[15px] w-[15px]
  opacity-0 
  pointer-events-none
  border-2 border-transparent
  transition-all duration-200 ease-in-out;
}

.btn .btn-border.tl {
  @apply top-0 left-0 border-t-watermelon-500 border-l-watermelon-500;

  transform: translate(var(--btn-border-offset), var(--btn-border-offset));
}

.btn .btn-border.tr {
  @apply top-0 right-0 border-t-watermelon-500 border-r-watermelon-500;
  transform: translate(calc(var(--btn-border-offset) * -1), var(--btn-border-offset));
}

.btn .btn-border.br {
  @apply bottom-0 right-0 border-b-watermelon-500 border-r-watermelon-500;
  transform: translate(calc(var(--btn-border-offset) * -1), calc(var(--btn-border-offset) * -1));
}

.btn .btn-border.bl {
  @apply bottom-0 left-0 border-b-watermelon-500 border-l-watermelon-500;
  transform: translate(var(--btn-border-offset), calc(var(--btn-border-offset) * -1));
}

.sign-in .btn-container .btn img {
  @apply h-[42px];
}

.sign-in .btn-container .btn:hover {

  /* background-color: rgba(var(--pink-rgb), 0.25); */
}

.sign-in .btn-container .btn:hover .btn-border {
  --btn-border-offset: -6px;

  @apply opacity-100 transition-all duration-200 ease-in-out;
}

.overlay {
  @apply fixed inset-0 bottom-[43%] p-4 flex flex-col justify-between items-center text-center z-50
}

.overlay h1 {
  @apply mb-1 text-[#020021] dark:text-white text-5xl font-light text-opacity-90;
}

.overlay p {
  @apply text-[#020021] dark:text-white text-sm font-light text-opacity-90 leading-none tracking-widest;
}