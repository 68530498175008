[data-theme="light"] .project-add-edit {
  --range-bg: #FFFFFF;
  --range-border: 0.3px solid #272525;
}

[data-theme="dark"] .project-add-edit {
  --range-bg: var(--primary-text-color);
  --range-border: 1px solid var(--primary-text-color);
}

.project-add-edit {
  @apply container mx-auto space-y-4;
}

.project-add-edit .page-header {
  @apply 
    flex items-center gap-4;
}

.project-add-edit h2 {
  @apply text-xl;
}

.project-add-edit .page-header {
  @apply flex items-center gap-4;
}

.project-add-edit form .form-group-container {
  @apply w-full p-6 space-y-8 md:space-y-0 md:grid gap-y-6 md:gap-x-6 md:grid-cols-2;
}

.project-add-edit form .form-group__checkbox-group {
  @apply p-4 space-y-4
    bg-white bg-opacity-50
    dark:bg-black dark:bg-opacity-30 
    rounded
    border 
    border-[#e5e0df]
    dark:border-[#333];
}

.project-add-edit form .form-group__checkbox-group__checkbox {
  @apply flex items-center gap-2;
}

.project-add-edit form .form-group__checkbox-group__checkbox label {
  @apply m-0
}

.project-add-edit form .form-group input {
  @apply py-2 px-4 rounded rounded-t-none text-lg disabled:opacity-70 border-none;
}


.project-add-edit form .form-group select {
  @apply border-none py-2 px-4 rounded text-lg disabled:opacity-70;
}

.project-add-edit form button[type="submit"] {
  @apply 
  w-full max-w-[500px]
  justify-self-center
  col-span-2
}

.project-add-edit form .form-error {
  @apply text-watermelon-500 text-center font-bold;
}

/* SLIDER
======================================= */
input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */

  
  @apply border border-[#e5e0df] dark:border-[#333] bg-[#0561B7] bg-opacity-20;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  @apply 
    outline-transparent
    ring-2 ring-[#0561B7];
}

input[type=range]::-ms-track {
  @apply 
    w-full 
    bg-transparent
    border border-transparent
    text-transparent
    cursor-pointer;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  @apply 
    h-[37px] w-4
    bg-white hover:bg-gray-100
    dark:bg-gray-300 dark:hover:bg-white
    cursor-pointer;

  -webkit-appearance: none;
  border: var(--range-border);
  border-radius: 3px;
  margin-top: -15px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */

}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {

  border: var(--range-border);
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: var(--range-bg);
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {

  border: var(--range-border);
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: var(--range-bg);
  cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  background: var(--btn-gradient);
  border-radius: 1.3px;
  border: 0.2px solid var(--primary-text-color);
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--btn-gradient);
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: var(--btn-gradient);
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: var(--btn-gradient);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
}
input[type=range]:focus::-ms-fill-lower {
  background: var(--btn-gradient);
}
input[type=range]::-ms-fill-upper {
  background: var(--btn-gradient);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
}
input[type=range]:focus::-ms-fill-upper {
  background: var(--btn-gradient);
}