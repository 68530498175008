/* DIFFICULTY BAR
==================================== */
.difficulty-container {
  @apply w-full flex items-center gap-2;
}

.difficulty-container .difficulty-label {
  @apply text-black dark:text-white capitalize;
}

.difficulty-container svg {
  @apply text-[#0561B7]
}

.difficulty-container svg.lowest {
  @apply text-[#2DB300];
}

.difficulty-container svg.low {
  @apply text-[#3AE500];
}

.difficulty-container svg.moderate {
  @apply text-[#FF7347];
}

.difficulty-container svg.high {
  @apply text-[#FF3C6D];
}

.difficulty-container svg.highest {
  @apply text-[#B2294B];
}