.keyword-categories-page {
  @apply
  relative
  container mx-auto
  space-y-4;
}

.keyword-categories-page h3 {
  @apply font-semibold
}

.keyword-categories-page section .form-groups {
  @apply 
    p-2 md:px-8 md:py-4 space-y-4
}

.keyword-categories-page form {
  @apply grid gap-4
}

.toggle-loader {
  height: 25px;
  width: 25px;
  pointer-events: none;
  display: inline-block;
  aspect-ratio: 1/1;
  width: 1.5rem;
  background-color: currentColor;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHN0cm9rZT0nIzAwMCcgdmlld0JveD0nMCAwIDI0IDI0JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxzdHlsZT4uc3Bpbm5lcl9WOG0xe3RyYW5zZm9ybS1vcmlnaW46Y2VudGVyO2FuaW1hdGlvbjpzcGlubmVyX3pLb2EgMnMgbGluZWFyIGluZmluaXRlfS5zcGlubmVyX1Y4bTEgY2lyY2xle3N0cm9rZS1saW5lY2FwOnJvdW5kO2FuaW1hdGlvbjpzcGlubmVyX1lwWlMgMS41cyBlYXNlLW91dCBpbmZpbml0ZX1Aa2V5ZnJhbWVzIHNwaW5uZXJfektvYXsxMDAle3RyYW5zZm9ybTpyb3RhdGUoMzYwZGVnKX19QGtleWZyYW1lcyBzcGlubmVyX1lwWlN7MCV7c3Ryb2tlLWRhc2hhcnJheTowIDE1MDtzdHJva2UtZGFzaG9mZnNldDowfTQ3LjUle3N0cm9rZS1kYXNoYXJyYXk6NDIgMTUwO3N0cm9rZS1kYXNob2Zmc2V0Oi0xNn05NSUsMTAwJXtzdHJva2UtZGFzaGFycmF5OjQyIDE1MDtzdHJva2UtZGFzaG9mZnNldDotNTl9fTwvc3R5bGU+PGcgY2xhc3M9J3NwaW5uZXJfVjhtMSc+PGNpcmNsZSBjeD0nMTInIGN5PScxMicgcj0nOS41JyBmaWxsPSdub25lJyBzdHJva2Utd2lkdGg9JzMnPjwvY2lyY2xlPjwvZz48L3N2Zz4=);
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHN0cm9rZT0nIzAwMCcgdmlld0JveD0nMCAwIDI0IDI0JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxzdHlsZT4uc3Bpbm5lcl9WOG0xe3RyYW5zZm9ybS1vcmlnaW46Y2VudGVyO2FuaW1hdGlvbjpzcGlubmVyX3pLb2EgMnMgbGluZWFyIGluZmluaXRlfS5zcGlubmVyX1Y4bTEgY2lyY2xle3N0cm9rZS1saW5lY2FwOnJvdW5kO2FuaW1hdGlvbjpzcGlubmVyX1lwWlMgMS41cyBlYXNlLW91dCBpbmZpbml0ZX1Aa2V5ZnJhbWVzIHNwaW5uZXJfektvYXsxMDAle3RyYW5zZm9ybTpyb3RhdGUoMzYwZGVnKX19QGtleWZyYW1lcyBzcGlubmVyX1lwWlN7MCV7c3Ryb2tlLWRhc2hhcnJheTowIDE1MDtzdHJva2UtZGFzaG9mZnNldDowfTQ3LjUle3N0cm9rZS1kYXNoYXJyYXk6NDIgMTUwO3N0cm9rZS1kYXNob2Zmc2V0Oi0xNn05NSUsMTAwJXtzdHJva2UtZGFzaGFycmF5OjQyIDE1MDtzdHJva2UtZGFzaG9mZnNldDotNTl9fTwvc3R5bGU+PGcgY2xhc3M9J3NwaW5uZXJfVjhtMSc+PGNpcmNsZSBjeD0nMTInIGN5PScxMicgcj0nOS41JyBmaWxsPSdub25lJyBzdHJva2Utd2lkdGg9JzMnPjwvY2lyY2xlPjwvZz48L3N2Zz4=);
  /* -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nNDQnIGhlaWdodD0nNDQnIHZpZXdCb3g9JzAgMCA0NCA0NCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyBzdHJva2U9JyNmZmYnPjxnIGZpbGw9J25vbmUnIGZpbGwtcnVsZT0nZXZlbm9kZCcgc3Ryb2tlLXdpZHRoPScyJz48Y2lyY2xlIGN4PScyMicgY3k9JzIyJyByPScxJz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSdyJyBiZWdpbj0nMHMnIGR1cj0nMS44cycgdmFsdWVzPScxOyAyMCcgY2FsY01vZGU9J3NwbGluZScga2V5VGltZXM9JzA7IDEnIGtleVNwbGluZXM9JzAuMTY1LCAwLjg0LCAwLjQ0LCAxJyByZXBlYXRDb3VudD0naW5kZWZpbml0ZScgLz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSdzdHJva2Utb3BhY2l0eScgYmVnaW49JzBzJyBkdXI9JzEuOHMnIHZhbHVlcz0nMTsgMCcgY2FsY01vZGU9J3NwbGluZScga2V5VGltZXM9JzA7IDEnIGtleVNwbGluZXM9JzAuMywgMC42MSwgMC4zNTUsIDEnIHJlcGVhdENvdW50PSdpbmRlZmluaXRlJyAvPjwvY2lyY2xlPjxjaXJjbGUgY3g9JzIyJyBjeT0nMjInIHI9JzEnPjxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9J3InIGJlZ2luPSctMC45cycgZHVyPScxLjhzJyB2YWx1ZXM9JzE7IDIwJyBjYWxjTW9kZT0nc3BsaW5lJyBrZXlUaW1lcz0nMDsgMScga2V5U3BsaW5lcz0nMC4xNjUsIDAuODQsIDAuNDQsIDEnIHJlcGVhdENvdW50PSdpbmRlZmluaXRlJyAvPjxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9J3N0cm9rZS1vcGFjaXR5JyBiZWdpbj0nLTAuOXMnIGR1cj0nMS44cycgdmFsdWVzPScxOyAwJyBjYWxjTW9kZT0nc3BsaW5lJyBrZXlUaW1lcz0nMDsgMScga2V5U3BsaW5lcz0nMC4zLCAwLjYxLCAwLjM1NSwgMScgcmVwZWF0Q291bnQ9J2luZGVmaW5pdGUnIC8+PC9jaXJjbGU+PC9nPjwvc3ZnPg==); */
  /* mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nNDQnIGhlaWdodD0nNDQnIHZpZXdCb3g9JzAgMCA0NCA0NCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyBzdHJva2U9JyNmZmYnPjxnIGZpbGw9J25vbmUnIGZpbGwtcnVsZT0nZXZlbm9kZCcgc3Ryb2tlLXdpZHRoPScyJz48Y2lyY2xlIGN4PScyMicgY3k9JzIyJyByPScxJz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSdyJyBiZWdpbj0nMHMnIGR1cj0nMS44cycgdmFsdWVzPScxOyAyMCcgY2FsY01vZGU9J3NwbGluZScga2V5VGltZXM9JzA7IDEnIGtleVNwbGluZXM9JzAuMTY1LCAwLjg0LCAwLjQ0LCAxJyByZXBlYXRDb3VudD0naW5kZWZpbml0ZScgLz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSdzdHJva2Utb3BhY2l0eScgYmVnaW49JzBzJyBkdXI9JzEuOHMnIHZhbHVlcz0nMTsgMCcgY2FsY01vZGU9J3NwbGluZScga2V5VGltZXM9JzA7IDEnIGtleVNwbGluZXM9JzAuMywgMC42MSwgMC4zNTUsIDEnIHJlcGVhdENvdW50PSdpbmRlZmluaXRlJyAvPjwvY2lyY2xlPjxjaXJjbGUgY3g9JzIyJyBjeT0nMjInIHI9JzEnPjxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9J3InIGJlZ2luPSctMC45cycgZHVyPScxLjhzJyB2YWx1ZXM9JzE7IDIwJyBjYWxjTW9kZT0nc3BsaW5lJyBrZXlUaW1lcz0nMDsgMScga2V5U3BsaW5lcz0nMC4xNjUsIDAuODQsIDAuNDQsIDEnIHJlcGVhdENvdW50PSdpbmRlZmluaXRlJyAvPjxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9J3N0cm9rZS1vcGFjaXR5JyBiZWdpbj0nLTAuOXMnIGR1cj0nMS44cycgdmFsdWVzPScxOyAwJyBjYWxjTW9kZT0nc3BsaW5lJyBrZXlUaW1lcz0nMDsgMScga2V5U3BsaW5lcz0nMC4zLCAwLjYxLCAwLjM1NSwgMScgcmVwZWF0Q291bnQ9J2luZGVmaW5pdGUnIC8+PC9jaXJjbGU+PC9nPjwvc3ZnPg==); */
}