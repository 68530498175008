/* MEDICATION OVERVIEW */
.medication-overview-container {
  @apply p-2 md:p-4 flex flex-col gap-2 md:gap-4
}
.overview-bar {
  @apply flex flex-col md:flex-row gap-2 md:gap-4
}

.overview-bar h3 {
  @apply font-bold;
}

.medication-overview-container .info-container {
  @apply p-4
    flex flex-col gap-2 flex-1 
    bg-[#f5f5f5] bg-opacity-100
    dark:bg-[#0561B7] dark:bg-opacity-5
    rounded
    border 
    border-[#0561B7] 
    border-opacity-[0.25]
    dark:border-opacity-[0.35]
}

.medication-overview-container .info-container.loading {
  @apply relative min-h-[500px] grid place-items-center w-full col-span-3;
}

.medication-overview-container .overview-bar.loading {
  @apply relative min-h-[500px] grid place-items-center grid-cols-1 col-span-3 
}

.medication-overview-container .overview-bar .overview-item {
  @apply flex gap-4
}
