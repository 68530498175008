.slider-container {
  @apply hidden md:block relative w-full overflow-hidden space-y-2;
  opacity: 0;
  animation: fadeIn 500ms forwards;
  animation-delay: 2000ms
}

.slider-container .swipe-page {
  position: absolute;
  width: 100%;
  min-height: 450px;
  will-change: transform;
  opacity: 0;
  animation: fadeIn 400ms ease-in-out forwards;
  animation-delay: 400ms;
}

.slider-container .swipe-page > div {
  touch-action: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.slider-container .container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.slider-container .swipe-wrapper {
  width: 100%;
  min-height: 450px;
  overflow: hidden;
}

.slider-container .dot {
  @apply 
    h-[10px] w-[10px] 
    mx-[2px] 
    inline-block 
    rounded-full 
    bg-booberry-500 bg-opacity-50
    dark:bg-[#FCFCFC] dark:bg-opacity-25 
    cursor-pointer;
}

.slider-container .dot.active {
  @apply 
    bg-booberry-500 bg-opacity-100
    dark:bg-[#FCFCFC] scale-125
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slider-container h3 {
  @apply text-sm;
}

.slider-container .nav-button {
  @apply 
    border border-[#0561B7] text-[#0561B7]
    dark:border-white dark:text-white
    rounded h-[32px] w-[32px] grid place-items-center
}

.slider-container .nav-button.active {
  @apply 
  bg-white bg-opacity-50
  border-[#0561B7] text-[#0561B7]
  dark:bg-white dark:bg-opacity-20 
  dark:border-watermelon-500 dark:text-watermelon-500
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}